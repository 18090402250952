import {Agent, getUnitedStatesTimeZoneShortName, ViewBase} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';

export const columns: Column<ViewBase<Agent>>[] = [
  {
    id: 'createdAt',
    label: 'Created At',
    sortable: true,
    selector: row => (row.createdAt ? format(row.createdAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
    export: row =>
      row.createdAt
        ? format(utcToZonedTime(new Date(row.createdAt.toDate()), 'UTC'), 'MM dd yyyy h:mm a')
        : '',
  },
  {
    id: 'firstName',
    label: 'First Name',
    sortable: true,
    export: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    sortable: true,
    export: true,
  },
  {
    id: 'group.name',
    label: 'Group',
    sortable: true,
    selector: row => row.group.name,
    export: row => row.group.name,
  },
  {
    id: 'role',
    label: 'Role',
    sortable: true,
    export: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    export: true,
  },
  {
    id: 'phone',
    label: 'Phone',
    sortable: true,
    export: true,
  },
  {
    id: 'agentCode',
    label: 'Agent Code',
    sortable: true,
    export: true,
  },
  {
    id: 'masterName',
    label: 'Master Agent',
    sortable: true,
    export: row => row.masterName ?? '',
  },
  {
    id: 'agentStatus',
    label: 'Status',
    sortable: true,
    export: true,
  },
  {
    id: 'lastLoginAt',
    label: 'Last Login',
    sortable: true,
    selector: row => (row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
    export: row =>
      row.lastLoginAt
        ? format(utcToZonedTime(new Date(row.lastLoginAt.toDate()), 'UTC'), 'MM dd yyyy h:mm a')
        : '',
  },
];

export const exportColumns: Column<ViewBase<Agent>>[] = [
  {
    id: 'createdAt',
    label: 'Created At',
    sortable: true,
    export: row =>
      row.createdAt
        ? format(utcToZonedTime(new Date(row.createdAt.toDate()), 'UTC'), 'MM dd yyyy h:mm a')
        : '',
  },
  {
    id: 'firstName',
    label: 'First Name',
    sortable: true,
    export: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    sortable: true,
    export: true,
  },
  {
    id: 'phone',
    label: 'Phone Number',
    sortable: true,
    export: row => row.phone ?? '',
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    export: true,
  },
  {
    id: 'timeZoneId',
    label: 'Time Zone',
    sortable: true,
    export: row => getUnitedStatesTimeZoneShortName(row.timeZoneId) ?? '',
  },
  {
    id: 'office.name',
    label: 'Office Name',
    sortable: true,
    export: row => row.office?.name ?? '',
  },
  {
    id: 'office.address',
    label: 'Address',
    sortable: true,
    export: row => row.office?.address ?? '',
  },
  {
    id: 'office.address2',
    label: 'Address Line 2',
    sortable: true,
    export: row => row.office?.address2 ?? '',
  },
  {
    id: 'office.city',
    label: 'City',
    sortable: true,
    export: row => row.office?.city ?? '',
  },
  {
    id: 'office.state',
    label: 'State',
    sortable: true,
    export: row => row.office?.state ?? '',
  },
  {
    id: 'office.zipCode',
    label: 'ZIP',
    sortable: true,
    export: row => row.office?.zipCode ?? '',
  },
  {
    id: 'adminContact.name',
    label: 'Admin Contact Name',
    sortable: true,
    export: row => row.adminContact?.name ?? '',
  },
  {
    id: 'adminContact.email',
    label: 'Admin Contact Email',
    sortable: true,
    export: row => row.adminContact?.email ?? '',
  },
  {
    id: 'group.name',
    label: 'Group',
    sortable: true,
    selector: row => row.group.name,
    export: row => row.group.name,
  },
  {
    id: 'role',
    label: 'Role',
    sortable: true,
    export: true,
  },
  {
    id: 'agentCode',
    label: 'Agent Code',
    sortable: true,
    export: true,
  },
  {
    id: 'masterName',
    label: 'Master Agent',
    sortable: true,
    export: row => row.masterName ?? '',
  },
  {
    id: 'agentStatus',
    label: 'Status',
    sortable: true,
    export: row => row.agentStatus,
  },
  {
    id: 'permissions.residuals',
    label: 'Residual Access',
    sortable: true,
    export: row => (row.permissions?.residuals === true ? 'Yes' : 'No'),
  },
  {
    id: 'siteJumpAgents',
    label: 'Site Jump Agents',
    sortable: true,
    export: row => row.siteJumpAgents?.map(x => x.fullName).join(';') ?? '',
  },
  {
    id: 'lastLoginAt',
    label: 'Last Login',
    sortable: true,
    selector: row => (row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
    export: row =>
      row.lastLoginAt
        ? format(utcToZonedTime(new Date(row.lastLoginAt.toDate()), 'UTC'), 'MM dd yyyy h:mm a')
        : '',
  },
];
